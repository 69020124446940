import {
  EditorState,
  SidebarBottomButtonTitles,
} from "ee/entities/IDE/constants";
import type { IDESidebarButton } from "IDE";

export const BottomButtons: IDESidebarButton[] = [
  {
    state: EditorState.DATA,
    icon: "datasource-v3",
    tooltip: SidebarBottomButtonTitles.DATA,
    testId: SidebarBottomButtonTitles.DATA,
    urlSuffix: "datasource",
  },
  {
    state: EditorState.SETTINGS,
    icon: "settings-v3",
    tooltip: SidebarBottomButtonTitles.SETTINGS,
    testId: SidebarBottomButtonTitles.SETTINGS,
    urlSuffix: "settings",
  },
];
